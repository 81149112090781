<template>
  <app-layout v-if="$route.meta.middleware">
    <div class="container mx-auto h-full flex flex-col md:flex-row w-full">
      <div class="md:px-5 py-2 md:py-10 lg:w-1/5 px-5">
        <app-button-menu
          class="
            md:mb-5 md:p-0
            mb-3
            w-full
            md:w-auto md:mx-0
            text-xl
            font-semibold
            bg-white
            flex
            items-center
            md:hover:bg-white
            justify-between
          "
          @onMenuClick="toggleMenu"
        >
          <p class="text-black">
            {{ $t("invoice.menu.title") }}
          </p>
          <app-icon
            name="ChevronRightIcon"
            :class="[
              'h-6 w-6 text-black md:hidden transform',
              showMenu ? 'rotate-90' : 'rotate-0',
            ]"
          />
        </app-button-menu>
        <app-button-menu
          :class="[
            'w-full space-x-3 items-center font-medium border-l-2 md:flex transition-all',
            showMenu ? 'flex' : 'hidden',
          ]"
          v-for="menu in menu_items"
          v-bind:key="menu.title"
          :rounded="false"
          :fullWidth="true"
          @onMenuClick="onMenuClick(menu)"
          :active="isCurrentPath(menu.path)"
          :disabled="menu.disabled"
          :separator="menu.separator"
        >
          <p :class="['text-left h-5 truncate ...', getMenuColor(menu)]">
            {{ menu.title }}
          </p>
        </app-button-menu>
      </div>
      <div class="md:w-4/5 row-span-5">
        <router-view />
      </div>
    </div>
  </app-layout>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu_items: [
        // {
        //   id: 1,
        //   title: this.$t("invoice.menu.overview"),
        //   path: "/invoice/overview",
        // },
        {
          id: 1,
          title: this.$t("invoice.menu.list"),
          path: "/invoice/index",
        },
        { id: 2, title: "", separator: true },
        {
          id: 3,
          title: this.$t("invoice.menu.report"),
          separator: true,
        },
        {
          id: 4,
          title: this.$t("invoice.menu.aging_report"),
          path: "/invoice/report/aging",
        },
      ],
      showMenu: false,
    };
  },

  computed: {},

  beforeCreate() {},

  methods: {
    onMenuClick(menu) {
      if (menu.path) {
        this.$router.push({ path: menu.path });
      }
    },

    isCurrentPath(path) {
      return this.$route.path == path;
    },

    getMenuColor(menu) {
      if (this.isCurrentPath(menu.path)) return "text-primary";
      if (menu.separator) return "text-gray";
      return "text-black";
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
